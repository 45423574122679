
<template>
    <a-card title="人员列表" :bordered="false">
        <custom-table
            row-key="id"
            :loading="loading"
            :data-source="dataList"
            :columns="tableColumns"
            :pagination="pagination"
            :scroll="scrollConfig"
            @change="handleTableChange"
        />
    </a-card>
</template>
<script>
import { Card as ACard } from 'ant-design-vue'
import CustomTable from '@components/CustomTable'

import initTable from '@mixins/initTable'

import { isEmpty } from '@utils'

import { tableColumns } from './config'

import getListService from '@service/marginManagement/marginUtilization/getMemberRosterListService'

export default {
    name: 'PaymentDetail',
    components: {
        ACard,
        CustomTable
    },
    mixins: [initTable],
    data() {
        return {
            tableColumns: Object.freeze(tableColumns)
        }
    },
    computed: {
        marginUsageRecordId() {
            return this.$route.query.id
        }
    },
    watch: {
        marginUsageRecordId: {
            handler() {
                this.getList()
            }
        }
    },
    methods: {
        // 获取分页列表数据
        async getList() {
            const { marginUsageRecordId } = this
            if (isEmpty(marginUsageRecordId)) return

            try {
                this.loading = true

                const _params = {
                    ...this.params,
                    marginUsageRecordId
                }
                const result = await getListService(_params)

                this.total = result.total || 0
                this.dataList = result.records || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
