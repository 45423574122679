import { DEFAULT_PAGES_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from '@config'

import tableShowTotal from '@mixins/tableShowTotal'

import { filterParams } from '@utils/search'

export default {
    mixins: [tableShowTotal],
    data() {
        return {
            total: 0,
            current: 1,
            limit: DEFAULT_PAGES_SIZE,

            dataList: [],
            loading: false,
            scrollConfig: {
                x: 1000,
                y: 500
            }
        }
    },
    computed: {
        pagination() {
            return {
                total: +this.total,
                pageSize: this.limit,
                current: this.current,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
                showTotal: (total, range) => this.tableShowTotal(total, range),

                ...this.customizePagination || {}
            }
        },
        params() {
            return {
                size: this.limit,
                current: this.current,
                ...filterParams(this.form)
            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleTableChange(pagination) {
            this.current = pagination.current
            this.limit = pagination.pageSize

            this.getList()
        }
    }
}
