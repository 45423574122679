import axios from '@service'
import { GET_MEMBER_ROSTER_LIST } from './api'
import { HTTP_ERR_SUCCESS } from '@constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@constant/error'

// 获取支付明细 - 人员列表数据
export default async function getMemberRosterListService(payload = {}, config = {}) {
    const param = {
        ...GET_MEMBER_ROSTER_LIST,
        ...config,
        params: {
            size: payload?.size,
            current: payload?.current
        },
        data: {
            marginUsageRecordId: payload?.marginUsageRecordId
        }
    }

    const { data } = await axios(param)

    if (data.code === HTTP_ERR_SUCCESS) {
        return data.result || {}
    } else {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
