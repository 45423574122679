/**
 * 保证金管理 / 保证金动用
 */
import { SAFETY_MFG_URL } from '@config'

// 保证金动用 - 检查选择的工程/项目
export const CHECK_SELECTED_PROJECT = {
    url: `${SAFETY_MFG_URL}/margin_usage_record/employ`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}

// 动用信息
export const GET_UTILIZATION_INFO = {
    url: `${SAFETY_MFG_URL}/margin_usage_record/getById`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

// 取消动用
export const CANCEL_UTILIZATION = {
    url: `${SAFETY_MFG_URL}/margin_usage_record/cancel`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}

// 处理结果录入
export const SUBMIT_PROCESS_RESULT = {
    url: `${SAFETY_MFG_URL}/margin_usage_record/input`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}

// 获取支付明细 - 人员列表数据
export const GET_MEMBER_ROSTER_LIST = {
    url: `${SAFETY_MFG_URL}/margin_usage_member_roster/page`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}
