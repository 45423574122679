import { empty as emptyFilter } from '@utils'
import { withYuanUnitAmountFilter } from '@utils/filter'

export const tableColumns = [
    {
        title: '姓名',
        dataIndex: 'name',
        width: 150,
        customRender: val => emptyFilter(val)
    },
    {
        title: '身份证号',
        dataIndex: 'idNumber',
        width: 200,
        customRender: val => emptyFilter(val)
    },
    {
        title: '银行名称',
        dataIndex: 'bankName',
        width: 300,
        customRender: val => emptyFilter(val)
    },
    {
        title: '银行卡号',
        dataIndex: 'bankCard',
        width: 200,
        customRender: val => emptyFilter(val)
    },
    {
        title: '支付方式',
        dataIndex: 'paymentMethodText',
        width: 120,
        customRender: val => emptyFilter(val)
    },
    {
        title: '支付金额',
        dataIndex: 'paymentAmount',
        width: 180,
        customRender: val => withYuanUnitAmountFilter(val)
    },
    {
        title: '联系方式',
        dataIndex: 'phone',
        width: 200,
        customRender: val => emptyFilter(val)
    }
]
